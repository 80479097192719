import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header'; 
import Footer from './Footer'; 
import './Home.css';
import setIntImg from './images/SETInt.png';
import intAppImg from './images/intApp.png';
import anesImg from './images/anes.png';
import coachImg from './images/coach.png';
import TestimonialsCard from './TestimonialsCard';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useMediaQuery } from 'react-responsive';

function Home() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const courseItems = [
        { text: 'Surgical Education & Training Interview Course', img: setIntImg, alt: 'SET Interview', path: '/course_1' },
        { text: 'Unaccredited Registrar Interview Course', img: intAppImg, alt: 'SET Application', path: '/course_2' },
        { text: 'Anaesthesia Interview Course', img: anesImg, alt: 'Anaesthesia Interview', path: '/course_3' },
        { text: 'Online 1:1 Interview Coaching', img: coachImg, alt: 'Online Coaching', path: '/course_4' },
    ];

    const renderCourseItem = (item) => (
        <div className="vertical-card">
            <p className="card-text">{item.text}</p>
            <img src={item.img} alt={item.alt} />
            <Link to={item.path} className="learn-more-button1">Learn More</Link>
        </div>
    );

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };

    return (
        <div className="home-container">
            <Header />
            <div class="home-card">
    <div class="overlay-text">Preparation starts now!</div>
    <Link to="/courses" className="enrol-button">Enrol</Link>
</div>
            <h2 className="home-title">Medical Interview Courses</h2>
            <div className="courses-card">
                {courseItems.map(renderCourseItem)}
            </div>
            <h2 className="home-title1">About Us</h2> 
            <div className="about1-card">
                <div style={{display: 'flex'}}>
                    {!isMobile && <img src={setIntImg} alt="SET Interview" style={{width: '40%', borderRadius: '10px 0 0 10px'}}/>}
                    <p style={{
                        margin: isMobile ? 10: 80,
                        fontSize: isMobile ? '17px' : '22px', // smaller font size for mobile
                        fontStyle: 'italic', 
                        color: 'rgba(18, 85, 126, 1)',
                        textAlign: 'center', 
                        lineHeight: '2', 
                        letterSpacing: '1.5px',
                        width: isMobile ? '80%' : '60%',
                        padding: isMobile? '50px 20px' : '0 20px'
                    }}>
                        iPrep is an Australasian based organisation that provides specialist medical and surgical interview preparation courses to propel health professionals through competitive career interviews.
                    </p> 
                </div>
            </div>
            <h2 className="home-title" style={{zIndex: 1}}>Our Clients Say...</h2> 
            <TestimonialsCard />
            <div>
            <Footer />
        </div>
        </div>
        
    );
}

export default Home;