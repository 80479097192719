import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  getFirestore,
  onSnapshot,
  getDoc, 
  doc
} from "firebase/firestore";

export const getOneTimeCheckoutUrl = async (app, priceId, courseId) => {
  const auth = getAuth(app);
  const userId = auth.currentUser && auth.currentUser.uid;
  const successUrl = `${window.location.origin}/payment-success?courseId=${courseId}`;

  if (!userId) throw new Error("User is not authenticated");

  const db = getFirestore(app);

  // Fetch the user's document from both collections
  const userRefNz = doc(db, 'users_nz', userId);
  const userRefAu = doc(db, 'users_au', userId);

  const userSnapNz = await getDoc(userRefNz);
  const userSnapAu = await getDoc(userRefAu);

  let userCollection;
  if (userSnapNz.exists()) {
    userCollection = 'users_nz';
  } else if (userSnapAu.exists()) {
    userCollection = 'users_au';
  } else {
    throw new Error("No such document for user!");
  }

  // Use the correct collection
  const checkoutSessionRef = collection(
    db,
    userCollection,
    userId,
    "checkout_sessions"
  );

  const docRef = await addDoc(checkoutSessionRef, {
    mode: 'payment', // This is for one-time payments
    price: priceId,
    success_url: successUrl,
    cancel_url: window.location.origin,
  });

  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(docRef, (snap) => {
      const data = snap.data();
      const error = data && data.error;
      const url = data && data.url;

      if (error) {
        unsubscribe();
        reject(new Error(`An error occurred: ${error.message}`));
      }
      if (url) {
        console.log("Stripe Checkout URL:", url);
        unsubscribe();
        resolve(url);
      }
    });
  });
};