import React, { useEffect, useState } from 'react';
import Header from './Header';
import './RegIntCourse.css';
import courseImage from './images/regIntCourse.png';
import { auth, firestore, app } from './firebase';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { getOneTimeCheckoutUrl } from './stripePaymentOneOff';
import Footer from './Footer'; 
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress

export const initiateStripeCheckout = async (courseId, country) => {
  try {
    let priceId = "";
    if (["New Zealand"].includes(country)) {
      priceId = "price_1OwAkIGEgwA5jGP2snOEs4T9";
    } else if (["Australia"].includes(country)) {
      priceId = "price_1Ow0n8GJPK7NrRV54lBjrFBl";
    }
    if (!priceId) {
      console.error("Invalid country or priceId not found for country:", country);
      return;
    }
    const checkoutUrl = await getOneTimeCheckoutUrl(app, priceId, courseId);
    window.location.href = checkoutUrl;
  } catch (error) {
    console.error("Error initiating Stripe Checkout:", error);
  }
};

function RegIntCourse() {
  const courseTitle = "Unaccredited Registrar Interview Course";
  const courseDescription = "Preparing for SET Application Course is an intensive workshop which prepares you (Medical Student, House Officer/Resident/Junior Registrar/Un-accredited Registrar) for the competitive Surgical Education and Training (SET) application process. <br /><br /> The course covers all key components of the application to ensure you prepare the most competitive SET application possible to maximise your chance to be selected for an interview for SET training in New Zealand and Australia across all surgical specialties. <br /><br /> Give yourself a compelling advantage going into SET applications. Maximise your chances of being selected for an interview. Leave nothing to chance.";
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [courses, setCourses] = useState([]);
  const [country, setCountry] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state

  useEffect(() => {
    const fetchUserCountry = async () => {
       try {
        const response = await fetch('https://ipinfo.io/json?token=e845a679ae9a47');
        const data = await response.json();
        console.log("Country response received:", data); // Print the entire response
        if (data && data.country) {
          let countryName = '';
          switch (data.country) {
            case 'AU':
              countryName = 'Australia';
              break;
            case 'NZ':
              countryName = 'New Zealand';
              break;
            default:
              console.error('Unsupported country:', data.country);
              return;
          }
          setCountry(countryName);
          console.log("COUNTRY is: ", countryName)
        }
      } catch (error) {
        console.error('Error fetching user country:', error);
      }
    };
    const unsubscribe = onAuthStateChanged(auth, user => {
      setIsLoggedIn(!!user);
      if (user) {
        const fetchCountry = async () => {
          const userRefNz = doc(collection(firestore, 'users_nz'), user.uid);
          const userRefAu = doc(collection(firestore, 'users_au'), user.uid);
          const userSnapNz = await getDoc(userRefNz);
          const userSnapAu = await getDoc(userRefAu);
          let userSnap;
          if (userSnapNz.exists()) {
            userSnap = userSnapNz;
          } else if (userSnapAu.exists()) {
            userSnap = userSnapAu;
          } else {
            console.log('No such document for user!');
            return;
          }
          setCountry(userSnap.data().country);
        };
        fetchCountry();
      } else {
        // User is not logged in, fetch country based on IP
        fetchUserCountry();
      }
    });

    return unsubscribe;
  }, []);

  const handleBookClickNZ = async (courseId, country) => {
    setLoading(true);
    if (!isLoggedIn) {
      localStorage.setItem('postLoginAction', 'initiateStripeCheckout');
      localStorage.setItem('courseId', courseId);
      navigate('/login');
      setLoading(false);
    } else {
      try {
        await initiateStripeCheckout(courseId, country);
        // setLoading(false); is not needed here since we're navigating away
      } catch (error) {
        console.error("Error during Stripe Checkout:", error);
        setLoading(false); // Stop loading in case of an error
      }
    }
  };

  useEffect(() => {
    const fetchCourses = async () => {
      const coursesRef = collection(firestore, 'SET_course');
      const courseSnapshots = await getDocs(coursesRef);
      const coursesWithAttendees = await Promise.all(courseSnapshots.docs.map(async (doc) => {
        const attendeesRef = collection(doc.ref, 'attendees');
        const attendeesSnap = await getDocs(attendeesRef);
        const isFull = attendeesSnap.size >= 16;
        console.log(`Course ID: ${doc.id}, Attendees: ${attendeesSnap.size}`); // Log the number of attendees for each course
        return { ...doc.data(), id: doc.id, isFull };
      }));
  
      let courseData;
      if (isLoggedIn) {
        courseData = coursesWithAttendees.filter(course => course.Country === country);
      } else {
        courseData = coursesWithAttendees.filter(course => course.Country === country);
      }
      setCourses(courseData);
      console.log("Updated courses with isFull status:", courseData); // This logs the updated courses array
    };
  
    fetchCourses();
  }, [country, isLoggedIn]);

  console.log(courses);


  return (
    <div>
      <Header />
      <div className="set-course-container">
        <div className="reg-course-image-container">
          <img src={courseImage} alt="Course" className="reg-course-image" />
          <h1 className="reg-course-title">{courseTitle}</h1>
        </div>
        <div className="set-course-description-container">
          <p className="set-course-description" dangerouslySetInnerHTML={{ __html: courseDescription }}></p>
        </div>
        <div className="set-course-card-container">
  <div className="set-course-card">
    <div className="course-list">
      {courses && courses.map(course => (
        <div key={course.id} className="course-box">
          <div className="book-button-container">
        {course.isFull ? (
          <button style={{ backgroundColor: 'grey' }}>Full</button>
        ) : (
          <button onClick={() => handleBookClickNZ(course.id, course.Country)}>
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Book'}
          </button>
        )}
      </div>
          <h2>{course.Location}</h2>
          <p>{course.Address}</p>
          <p>{course.DateStart} - {course.DateFinish}</p>
          <p>{course.Time}</p>
        </div>
      ))}
    </div>
  </div>
</div>
<div className="new-course-card-container">
<div className="set-course-card">

  <h2 className="course-details-title">Course Details</h2>
  <p>Course Learning Objectives:</p>
  <ul>
    <li>Clearly understand the SET application process and how to streamline your path towards making a competitive SET application</li>
    <li>Create a roadmap to chart your path towards successful selection to SET with clear timeframes</li>
    <li>Understand the key components of the SET application and how to be most efficient with each component:</li>
    <ul>
      <li>CV</li>
      <li>Courses</li>
      <li>Research</li>
      <li>Exams</li>
      <li>References</li>
    </ul>
  </ul>

  <p>Course dates and locations can be selected upon booking courses.</p>
  <p>Our Team</p>
  <p>Our team includes medical and surgical consultants, fellows, and training registrars to provide a wealth of knowledge and skills for your advantage.</p>
  <p>Enrolment requirements</p>
  <p>Must be registered with New Zealand or Australia's Medical Council.</p>
</div>


</div>
          </div>
          <div>
            <Footer/>
          </div>
        </div>
      
  );
}

export default RegIntCourse;