// Profile.js
import React, { useEffect, useState } from 'react';
import { auth, firestore } from './firebase';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import Header from './Header'; 
import { useNavigate } from 'react-router-dom';
import { getCheckoutUrl } from './stripePayment';
import { app } from './firebase';
import './Profile.css';
import AdminTab from './AdminTab.js';
import Footer from './Footer'; 
import { CircularProgress } from '@mui/material'; // Import CircularProgress


function Profile() {
  const navigate = useNavigate();
  const user = auth.currentUser;
  const [displayName, setDisplayName] = useState('');
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add a state to track loading


  useEffect(() => {
    const fetchDisplayName = async () => {
      // Fetch the user's document from both collections
      const userRefNz = doc(collection(firestore, 'users_nz'), user.uid);
      const userRefAu = doc(collection(firestore, 'users_au'), user.uid);
  
      const userSnapNz = await getDoc(userRefNz);
      const userSnapAu = await getDoc(userRefAu);
  
      let userSnap;
      if (userSnapNz.exists()) {
        userSnap = userSnapNz;
      } else if (userSnapAu.exists()) {
        userSnap = userSnapAu;
      } else {
        console.log('No such document for user!');
        return;
      }
  
      const country = userSnap.data().country;
      setDisplayName(userSnap.data().displayName);
    };

    const courseCollections = ['SET_course', 'ABTcourse', 'OnlineCourse', 'RegIntCourse'];

    const fetchSubscriptions = async () => {
      setIsLoading(true);
      const subs = [];
    
      // Determine the user's country first, similar to fetchDisplayName
      const userRefNz = doc(collection(firestore, 'users_nz'), user.uid);
      const userRefAu = doc(collection(firestore, 'users_au'), user.uid);
    
      let userCountryRef;
      const userSnapNz = await getDoc(userRefNz);
      if (userSnapNz.exists()) {
        userCountryRef = 'users_nz';
      } else {
        userCountryRef = 'users_au'; // Default to 'users_au' if not found in 'users_nz'
      }
    
      for (let courseCollection of courseCollections) {
        const coursesRef = collection(firestore, courseCollection);
        const coursesSnapshot = await getDocs(coursesRef);
    
        for (const courseDoc of coursesSnapshot.docs) {
          const attendeesRef = collection(courseDoc.ref, 'attendees');
          const attendeeDocRef = doc(attendeesRef, user.uid);
          const attendeeDocSnap = await getDoc(attendeeDocRef);
    
          if (attendeeDocSnap.exists()) {
            // Use the determined userCountryRef for fetching payments
            const paymentsRef = collection(firestore, userCountryRef, user.uid, 'payments');
            const paymentsSnapshot = await getDocs(paymentsRef);
            let receiptUrls = []; // Initialize an array to hold all receipt URLs
    
            paymentsSnapshot.forEach((paymentDoc) => {
              const paymentData = paymentDoc.data();
              const charges = paymentData.charges;
              if (charges && charges.data && charges.data.length > 0) {
                // Assuming each charge contains a receipt URL, add it to the receiptUrls array
                const receiptUrl = charges.data[0].receipt_url; // Adjust this path if necessary
                console.log(`Receipt URL for ${paymentDoc.id}: ${receiptUrl}`); // Log the receipt URL for debugging
                receiptUrls.push(receiptUrl);
              }
            });
    
            // Add the course data along with the receiptUrls to the subs array
            subs.push({ ...courseDoc.data(), receiptUrls });
          }
        }
      }
    
      setSubscriptions(subs);
      setIsLoading(false);
    };

    if (user) {
      fetchDisplayName();
      fetchSubscriptions();
    }
  }, [user]);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/'); // Redirect to login or another page after successful logout
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const [activeTab, setActiveTab] = useState('Progress');

  const adminEmails = ['kaveshan.naidoo@gmail.com', 'medr@medrapp.net', 'zanazir@iprepmedical.com.au']; // Add the allowed email addresses here


  return (
    <div>
      <Header />
      <div style={{marginTop: '20px', color: 'rgba(18, 85, 126, 1)'}}>
    <h1 style={{color: 'rgba(18, 85, 126, 1)', textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>Welcome {displayName}</h1>
    <button style={{ padding: '10px', paddingLeft: '30px', paddingRight: '30px', width: '5%', textAlign: 'center', justifyContent: 'center', alignItems: 'center'}} onClick={handleLogout}>Logout</button>
  </div>
      <div className="profile-container">
      <div className="tabs">
      <div className={`tab ${activeTab === 'Progress' ? 'active' : ''}`} onClick={() => setActiveTab('Progress')}>Progress</div>
      <div className={`tab ${activeTab === 'Subscriptions' ? 'active' : ''}`} onClick={() => setActiveTab('Subscriptions')}>Subscriptions</div>
      {adminEmails.includes(user.email) && <div className={`tab ${activeTab === 'Admin' ? 'active' : ''}`} onClick={() => setActiveTab('Admin')}>Admin</div>}    </div>
               <div className="box-content">
                
          {activeTab === 'Progress' && <div></div>}
          {activeTab === 'Subscriptions' && (
            <>
            <h2 style={{ fontWeight: 'normal', color: 'rgba(18, 85, 126, 1)', textAlign: 'center' }}>Enrolled and upcoming:</h2> 
          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
              <CircularProgress /> {/* Show loading indicator */}
            </div>
          ) : subscriptions.length > 0 ? (
  subscriptions.map((course, index) => (
    <div key={index} className="course-card1">
      <h2>{course.Course} </h2>
      <p>Location: {course.Location}</p>
      <p>Address: {course.Address}</p>
      <p>Dates: {course.DateStart} - {course.DateFinish}</p>
      <p>Time: {course.Time}</p>
      {course.receiptUrls && course.receiptUrls.length > 0 && (
        course.receiptUrls.map((url, urlIndex) => (
          <a key={urlIndex} href={url} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
            <button>View Receipt</button>
          </a>
        ))
      )}
    </div>
  ))
) : (
  <p>No enrolled courses!</p>
)}
              
            </>
          )}
          {activeTab === 'Admin' && (
  <div>
    {activeTab === 'Admin' && <AdminTab />}
  </div>
)}
        </div>
      </div>
      <div>
        <Footer/>
      </div>
    </div>
  );
}

export default Profile;