import React, { useState } from 'react';
import './Header.css';
import logo from './images/headerlogo.png';
import { Link } from 'react-router-dom';
import { auth } from './firebase'; // Import the auth object
import { AiOutlineMenu } from 'react-icons/ai'; // Import the hamburger menu icon

function Header() {
    const user = auth.currentUser; // Get the current user
    const [showNav, setShowNav] = useState(false); // State to toggle the navigation

    return (
        <div className="header">
            <img src={logo} alt="iPrepLogo" className="logo" />
            <AiOutlineMenu className="menu-icon" onClick={() => setShowNav(!showNav)} /> {/* Hamburger menu icon */}
            <nav className={`navigation ${showNav ? 'show' : ''}`}>
                <Link to="/" className="nav-button">Home</Link>
                {/*<Link to="/questions" className="nav-button">Question Bank</Link>*/}
                <Link to="/courses" className="nav-button">Courses</Link>
                <Link to="/tutorials" className="nav-button">Course Resources</Link> {/* Added Link to Tutorials */}
                <Link to="/about" className="nav-button">About</Link>
                <Link to="/contact" className="nav-button">Contact</Link>
                {user ? (
                    <Link to="/profile" className="nav-button register-login">
                        Profile
                    </Link>
                ) : (
                    <Link to="/login" className="nav-button register-login">
                        <span className="register-text">Enrol</span> or  Log in
                    </Link>
                )}
            </nav>
        </div>
    );
}

export default Header;