import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import Quote from './Quote';
import './TestimonialsCard.css';
import { useSwipeable } from 'react-swipeable';

const TestimonialsCard = () => {
    const quotes = [
        "The iPrep Medical course helped me grasp the SET interview structure and identify areas that I needed to develop. The course covered what a medical interview requires, pitfalls and perils for a successful interview outcome.\n\nThere were excellent techniques for various types of interview scenarios.\n\nEach practice session was coupled with specific feedback and critique.\n\nI found this to be an outstanding course to help guide my development leading up to the big day.\n\n - Dan, Orthopaedic Surgery",
        "The course provided excellent frameworks and techniques to improve the quality of my interview responses. The direction provided by the course helped focus my study and preparation leading up to the interview.\n\nThis course provided an extremely supportive environment to develop skills and techniques I hadn't covered in previous areas of my preparation.\n\n - Mike, Orthopaedic Surgery",
        "The interview coaching was transformative. The content and delivery made the experience fantastic. I was able to grasp the important concepts of communication, especially the non-verbal aspects, and apply it to the interview and also to work in general.\n\nThe coaching was an invaluable experience where I was able to learn and review key concepts and apply them in a safe space.\n\nWithout this course, I would not have performed as well in the interview and I recommend this to anyone preparing for their SET interviews.\n\n - Keith, General Surgery",
        "The most important thing I learnt was the importance of non-verbal communication with strategies to master this.\n\n - Jared, Orthopaedic Surgery",
        "This course completely exceeded my expectations. I was able to learn important frameworks other than what is already commonly known. These gave me confidence and clarity when formulating responses to execute responses with finesse.\n\nThe practice interview stations were brilliant and the feedback from the course facilitators was outstanding. Thank you iPrep Medical.\n\n - Mason, Surgery",
        "Fantastic course from start to finish. A great mix of skills teaching and opportunities for practice with insightful feedback from course facilitators. What I liked most was the big focus on interview strategy and technique rather than content. Thank you for putting this course together.\n\n - Dean, General Surgery",
        "This course very much exceeded my expectations. I was hopeful yet not expecting a course of such quality and class. The course very quickly identified my deficiencies then provided strategies to improve these. I felt so much more confident going into SET interviews especially because I knew how to look and sound confident even with difficult questions. The course is priced fairly because the benefits of this course are priceless.\n\n - Joon, Orthopaedic Surgery",
        "This course was so much better than I expected. It highlighted the importance of non-verbal skills in your interview technique. My shortcomings were highlighted very early then strategies were provided to improve these deficient areas. I was also taught how to manage nerves which for me is one of the most important things I have taken away from the course.\n\n - Myra, Surgery",
        "This course was very impactful and directly influenced my approach when prepping for the SET interview. I learned how interview panels form perceptions of candidates and how to present confident in the interview.\n\nThere were some eye opening moments regarding delivery taught during the course which directly impacted how I focused my preparation leading up to the interview.\n\n - Amir, General Surgery",
        "What an excellent course. I cannot recommend this enough. I was able to learn key strategies for managing nerves and performing under pressure and was able to put these skills into practice. What a difference these skills have made.\n\n - Sarah, Orthopaedic Surgery",
        "This course was instrumental in transforming my interview preparation. It is a well structured course with excellent feedback from the various course facilitators. The course has a high price but this is totally justified with the quality of it and my success as a result.\n\n - Jordan, Orthopaedic Surgery",
        "I felt so much more confident having done the SET interview prep course. It helped me to be able to present myself confidently in the interview. There was a nice balance between teaching and interview practice stations that helped me incorporate the skills taught. I feel that the course significantly helped me so will be recommending it to others.\n\n - Dane, General Surgery"
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [inProp, setInProp] = useState(true);

    // Function to go to the next quote
    const nextQuote = () => {
        setInProp(false);
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % quotes.length);
            setInProp(true);
        }, 500); // Adjust timing based on your transition
    };

    // Function to go to the previous quote
    const prevQuote = () => {
        setInProp(false);
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + quotes.length) % quotes.length);
            setInProp(true);
        }, 500); // Adjust timing based on your transition
    };

    // Function to go to a specific quote
    const goToQuote = (index) => {
        setInProp(false);
        setTimeout(() => {
            setCurrentIndex(index);
            setInProp(true);
        }, 500); // Adjust timing based on your transition
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => nextQuote(),
        onSwipedRight: () => prevQuote(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
      });

      return (
        <div {...handlers} className="testimonials-card">
            <div className="navigation-arrows">
                <button onClick={prevQuote}>&lt;</button>
                <button onClick={nextQuote}>&gt;</button>
            </div>
            <div className="quotes-container">
                <CSSTransition in={inProp} timeout={500} classNames="fade">
                    <Quote text={quotes[currentIndex]} />
                </CSSTransition>
            </div>
            <div className="dots">
                {quotes.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${index === currentIndex ? 'active' : ''}`}
                        onClick={() => goToQuote(index)}
                    ></span>
                ))}
            </div>
        </div>
    );
};


export default TestimonialsCard;