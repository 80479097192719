import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, addDoc, doc, query, where } from 'firebase/firestore';import { firestore } from './firebase';

function AdminTab() {
  const [courses, setCourses] = useState([]);
  const [courseType, setCourseType] = useState('SET_course');
  const [showModal, setShowModal] = useState(false);
  const [newCourse, setNewCourse] = useState({
    Location: '',
    Address: '',
    DateStart: '',
    DateFinish: '',
    Time: '',
    Country: ''
  });
  const [showAttendees, setShowAttendees] = useState({});


  useEffect(() => {
    const fetchCourses = async () => {
      const coursesRef = collection(firestore, courseType);
      const courseSnapshots = await getDocs(coursesRef);
      const coursesWithAttendees = await Promise.all(courseSnapshots.docs.map(async (doc) => {
        const attendeesRef = collection(firestore, `${courseType}/${doc.id}/attendees`);
        const attendeesSnap = await getDocs(attendeesRef);
        return { ...doc.data(), id: doc.id, attendeesCount: attendeesSnap.size };
      }));
      setCourses(coursesWithAttendees);
    };
  
    fetchCourses();
  }, [courseType]);

  const handleShowAttendees = async (courseId) => {
    const attendeesRef = collection(firestore, `${courseType}/${courseId}/attendees`);
    const attendeesSnap = await getDocs(attendeesRef);
    const attendeesDetails = await Promise.all(attendeesSnap.docs.map(async (doc) => {
      const email = doc.data().email;
      // Search in users_au collection
      let userRef = collection(firestore, 'users_au');
      let querySnapshot = await getDocs(query(userRef, where('email', '==', email)));
      if (querySnapshot.empty) {
        // If not found in users_au, search in users_nz collection
        userRef = collection(firestore, 'users_nz');
        querySnapshot = await getDocs(query(userRef, where('email', '==', email)));
      }
      if (!querySnapshot.empty) {
        // Assuming email is unique and only one document will be found
        const userData = querySnapshot.docs[0].data();
        return {
          name: userData.displayName,
          email: userData.email,
          phoneNumber: userData.phoneNumber,
          specialty: userData.specialty
        };
      }
      return null; // In case no matching document is found
    }));
  
    // Filter out any null values if no matching document was found for an email
    const filteredAttendeesDetails = attendeesDetails.filter(detail => detail !== null);
  
    // Update the specific course with attendees' details
    setCourses(courses.map(course => course.id === courseId ? { ...course, attendees: filteredAttendeesDetails, attendeesCount: filteredAttendeesDetails.length } : course));
    // Toggle visibility
    setShowAttendees(prevState => ({ ...prevState, [courseId]: !prevState[courseId] }));
  };

  const handleDelete = async (id) => {
    const courseRef = doc(firestore, courseType, id);
    await deleteDoc(courseRef);
    setCourses(courses.filter(course => course.id !== id));
  };

  const courseTypeMapping = {
    "SET_course": "SET Interview Course",
    "RegIntCourse": "Registrar Interview Course",
    "ABTcourse": "Anaesthesia Course",
    "OnlineCourse": "1:1 Coaching"
  };
  
  const handleAdd = async () => {
    const courseRef = collection(firestore, courseType);
    // Create a course for New Zealand
    const newZealandCourse = { ...newCourse, Country: 'New Zealand', Course: courseTypeMapping[courseType] };
    const docRefNZ = await addDoc(courseRef, newZealandCourse);
    // Create a course for Australia
    const australiaCourse = { ...newCourse, Country: 'Australia', Course: courseTypeMapping[courseType] };
    const docRefAU = await addDoc(courseRef, australiaCourse);
  
    // Update the courses state with both new courses
    setCourses([...courses, { ...newZealandCourse, id: docRefNZ.id }, { ...australiaCourse, id: docRefAU.id }]);
    setShowModal(false);
  };

  const handleCourseTypeChange = (event) => {
    setCourseType(event.target.value);
  };

  const handleInputChange = (event) => {
    setNewCourse({ ...newCourse, [event.target.name]: event.target.value });
  };
  

  return (
    <div>
      <select value={courseType} onChange={handleCourseTypeChange}>
        <option value="SET_course">SET Interview Course</option>
        <option value="RegIntCourse">Registrar Interview Course</option>
        <option value="ABTcourse">Anaesthesia Course</option>
        <option value="OnlineCourse">1:1 Coaching</option>
      </select>
      {courses.map(course => (
  <div key={course.id}>
    <h2>{course.Location}</h2>
    <p>{course.Address}</p>
    <p>Attendees Location: {course.Country}</p>
    <p>{course.DateStart} - {course.DateFinish}</p>
    <p>{course.Time}</p>
    <p>Attendees: {course.attendeesCount}</p>
          <button onClick={() => handleShowAttendees(course.id)}>Show Attendees</button>
          {showAttendees[course.id] && course.attendees && (
  <ul>
    {course.attendees.map((attendee, index) => (
      <p key={index}>
        <p><strong>Name: {attendee.name}</strong> </p>
        <p>Email: {attendee.email}</p>
        <p>Phone Number: {attendee.phoneNumber}</p>
        <p>Specialty: {attendee.specialty}</p>
      </p>
    ))}
  </ul>
)}
    <button onClick={() => handleDelete(course.id)}>Delete</button>
  </div>
))}
      <button onClick={() => setShowModal(true)}>Add Course</button>
      {showModal && (
        <div>
          <input name="Location" value={newCourse.Location} onChange={handleInputChange} placeholder="Location" />
          <input name="Address" value={newCourse.Address} onChange={handleInputChange} placeholder="Address" />
          <input name="DateStart" value={newCourse.DateStart} onChange={handleInputChange} placeholder="DateStart" />
          <input name="DateFinish" value={newCourse.DateFinish} onChange={handleInputChange} placeholder="DateFinish" />
          <input name="Time" value={newCourse.Time} onChange={handleInputChange} placeholder="Time" />
          <button onClick={handleAdd}>OK</button>
        </div>
      )}
    </div>
  );
}

export default AdminTab;