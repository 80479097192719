import React, { useEffect, useState } from 'react';
import Header from './Header';
import './SETcourse.css';
import courseImage from './images/setCourse.png';
import { auth, firestore, app } from './firebase';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { getOneTimeCheckoutUrl } from './stripePaymentOneOff';
import Footer from './Footer'; 
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress


export const initiateStripeCheckout = async (courseId, country) => {
  try {
    let priceId = "";
    if (["New Zealand"].includes(country)) {
      priceId = "price_1OwAkIGEgwA5jGP2snOEs4T9";
    } else if (["Australia"].includes(country)) {
      priceId = "price_1Ow0n8GJPK7NrRV54lBjrFBl";
    }
    if (!priceId) {
      console.error("Invalid country or priceId not found for country:", country);
      return;
    }
    const checkoutUrl = await getOneTimeCheckoutUrl(app, priceId, courseId);
    window.location.href = checkoutUrl;
  } catch (error) { 
    console.error("Error initiating Stripe Checkout:", error);
  }
};

function SET_course() {
  const courseTitle = "SET Interview Courses";
  const courseDescription = "The Surgical Education & Training (SET) Course is an intensive workshop which prepares you (a Non-Training/ Unaccredited Surgical Registrar) for SET interviews. <br /><br /> The course breaks down the art of delivery essential for confidently presenting polished answers that demonstrate your suitability for the SET program and to become a future surgeon. <br /><br />The course also prepares you to best present content, how you can approach questions with structure and flair, and ensure your responses exhibit sophistication. The course provides you with simulated interview practice stations with real-time coaching and feedback. <br /><br />You will have a major advantage going into SET interviews and this course will give you the best chance possible of being selected for those highly competitive SET program positions. <br /><br />Take control of your preparation.  Chart your future.  Leave nothing to chance. ";
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [courses, setCourses] = useState([]);
  const [country, setCountry] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state

  useEffect(() => {
    const fetchUserCountry = async () => {
      try {
        const response = await fetch('https://ipinfo.io/json?token=e845a679ae9a47');
        const data = await response.json();
        console.log("Country response received:", data); // Print the entire response
        if (data && data.country) {
          let countryName = '';
          switch (data.country) {
            case 'AU':
              countryName = 'Australia';
              break;
            case 'NZ':
              countryName = 'New Zealand';
              break;
            default:
              console.error('Unsupported country:', data.country);
              return;
          }
          setCountry(countryName);
          console.log("COUNTRY is: ", countryName)
        }
      } catch (error) {
        console.error('Error fetching user country:', error);
      }
    };
    const unsubscribe = onAuthStateChanged(auth, user => {
      setIsLoggedIn(!!user);
      if (user) {
        const fetchCountry = async () => {
          const userRefNz = doc(collection(firestore, 'users_nz'), user.uid);
          const userRefAu = doc(collection(firestore, 'users_au'), user.uid);
          const userSnapNz = await getDoc(userRefNz);
          const userSnapAu = await getDoc(userRefAu);
          let userSnap;
          if (userSnapNz.exists()) {
            userSnap = userSnapNz;
          } else if (userSnapAu.exists()) {
            userSnap = userSnapAu;
          } else {
            console.log('No such document for user!');
            return;
          }
          setCountry(userSnap.data().country);
        };
        fetchCountry();
      } else {
        // User is not logged in, fetch country based on IP
        fetchUserCountry();
      }
    });

    return unsubscribe;
  }, []);

  const handleBookClickNZ = async (courseId, country) => {
    setLoading(true); // Start loading
    if (!isLoggedIn) {
      localStorage.setItem('postLoginAction', 'initiateStripeCheckout');
      localStorage.setItem('courseId', courseId);
      navigate('/login');
      setLoading(false); // Stop loading as we navigate away
    } else {
      try {
        await initiateStripeCheckout(courseId, country);
        // setLoading(false); is not needed here since we're navigating away
      } catch (error) {
        console.error("Error during Stripe Checkout:", error);
        setLoading(false); // Stop loading in case of an error
      }
    }
  };

  useEffect(() => {
    const fetchCourses = async () => {
      const coursesRef = collection(firestore, 'SET_course');
      const courseSnapshots = await getDocs(coursesRef);
      const coursesWithAttendees = await Promise.all(courseSnapshots.docs.map(async (doc) => {
        const attendeesRef = collection(doc.ref, 'attendees');
        const attendeesSnap = await getDocs(attendeesRef);
        const isFull = attendeesSnap.size >= 16;
        console.log(`Course ID: ${doc.id}, Attendees: ${attendeesSnap.size}`); // Log the number of attendees for each course
        return { ...doc.data(), id: doc.id, isFull };
      }));
  
      let courseData;
      if (isLoggedIn) {
        courseData = coursesWithAttendees.filter(course => course.Country === country);
      } else {
        courseData = coursesWithAttendees.filter(course => course.Country === country);
      }
      setCourses(courseData);
      console.log("Updated courses with isFull status:", courseData); // This logs the updated courses array
    };
  
    fetchCourses();
  }, [country, isLoggedIn]);

  console.log(courses);


  return (
    <div>
      <Header />
      <div className="set1-course-container">
        <div className="set1-course-image-container">
          <img src={courseImage} alt="Course" className="set1-course-image" />
          <h1 className="set1-course-title">{courseTitle}</h1>
        </div>
        <div className="set-course-description-container">
          <p className="set-course-description" dangerouslySetInnerHTML={{ __html: courseDescription }}></p>
        </div>
        <div className="set-course-card-container">
  <div className="set-course-card">
    <div className="course-list">
    {courses && courses.map(course => {
  console.log(`Rendering course ${course.id} with isFull status:`, course.isFull); // This logs the id and isFull status of each course before rendering the button
  return (
    <div key={course.id} className="course-box">
      <div className="book-button-container">
        {course.isFull ? (
          <button style={{ backgroundColor: 'grey' }}>Full</button>
        ) : (
          <button onClick={() => handleBookClickNZ(course.id, course.Country)}>
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Book'}
          </button>
        )}
      </div>
      <h2>{course.Location}</h2>
      <p>{course.Address}</p>
      <p>{course.DateStart} - {course.DateFinish}</p>
      <p>{course.Time}</p>
    </div>
  );
})}
    </div>
  </div>
</div>
<div className="new-course-card-container">
<div className="set-course-card">
<h2 className="course-details-title">Course Details</h2>
    <p>Course Learning Objectives:</p>
    <ul>
      <li>Form a clear understanding of the interview process and what to expect at the SET interviews</li>
      <li>Comprehend how interview panels form perceptions of candidates and what is needed to maximise interview points</li>
      <li>Master specific techniques and skills to present confident and polished answers in high stakes interview situations with clarity and flair</li>
      <li>Understand important frameworks to give you confidence and clarity when formulating responses to interview questions</li>
      <li>Execute with finesse, skills taught during the course in interview situations</li>
    </ul>
    <p>The interview stations during the course simulate the interview process to give a realistic experience of what to expect from the interview panel. They provide an opportunity to practice and consolidate the core skills learnt during the workshop. The interview stations will have questions with themes based on the RACS ten core competencies:</p>
    <ul>
      <li>Collaboration and teamwork</li>
      <li>Communication</li>
      <li>Cultural competence and cultural safety</li>
      <li>Health advocacy</li>
      <li>Judgement and clinical decision making</li>
      <li>Leadership and management</li>
      <li>Medical expertise</li>
      <li>Professionalism</li>
      <li>Scholarship and teaching</li>
      <li>Technical expertise</li>
    </ul>
    
    <p>Course dates and locations can be selected upon booking courses.</p>
    <p>Our Team</p>
    <p>Our team of facilitators include medical and surgical consultants, fellows, training registrars and other professionals with extensive interviewing experience to provide a wealth of knowledge and skills for your advantage.</p>
    <p>Enrolment requirements</p>
    <p>Must be registered with New Zealand or Australia's Medical Council.</p>
    <p>Dress Code</p>
    <p>Interview attire and grooming.</p>
    <p>Specialty specific SET entry requirements</p>
    <p>For full details on specialty specific selection requirements for SET, please visit <a href="http://www.surgeons.org">www.surgeons.org</a></p>
  </div>

</div>
          </div>
          <Footer/>
        </div>
      
  );
}

export default SET_course;