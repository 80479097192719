import React, { useState } from 'react';
import Header from './Header';
import './Contact.css';
import contactImage from './images/contact.png';

function Contact() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [specialty, setSpecialty] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false); // New state variable


    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Form submission started'); // Debugging line
        fetch('/api/sendEmail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                firstName,
                lastName,
                email,
                phoneNumber,
                specialty,
                message,
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const contentType = response.headers.get('Content-Type');
            if (contentType && contentType.includes('application/json')) {
                return response.json();
            } else {
                return response.text(); // Handle non-JSON responses
            }
        })
        .then(data => {
            console.log('Success:', data);
            setIsSubmitted(true);
            // Clear the form fields
            setFirstName('');
            setLastName('');
            setEmail('');
            setPhoneNumber('');
            setSpecialty('');
            setMessage('');
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };

    return (
        <div>
            <Header />
            <div className="contact-container">
                <div className="image-container">
                    <img src={contactImage} alt="Contact" />
                </div>
                <form onSubmit={handleSubmit}>
                    <h1 class="contact-us-heading">Contact Us</h1>
                    <input type="text" placeholder="First Name" required value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    <input type="text" placeholder="Last Name" required value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    <input type="email" placeholder="Email Address" required value={email} onChange={(e) => setEmail(e.target.value)} />
                    <input type="tel" placeholder="Phone Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                    <input type="text" placeholder="Specialty of Interest" value={specialty} onChange={(e) => setSpecialty(e.target.value)} />
                    <textarea placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} />
                    <button type="submit" className={isSubmitted ? "submitted" : ""}>
                        {isSubmitted ? "Thank You!" : "Submit"}
                    </button>                </form>
            </div>
        </div>
    );
}

export default Contact;