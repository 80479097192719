import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, firestore } from './firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import './Login.css';
import { initiateStripeCheckout } from './SETcourse'
import Header from './Header'; 
import Footer from './Footer'; 
import CircularProgress from '@mui/material/CircularProgress';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [registerName, setRegisterName] = useState('');  // <-- New state for name
  const [registerEmail, setRegisterEmail] = useState('');
  const [registerPassword, setRegisterPassword] = useState('');
  const [registerPhoneNumber, setRegisterPhoneNumber] = useState('');
  const [registerSpecialty, setRegisterSpecialty] = useState('');
  const [registerMCNZ_AHPRA, setRegisterMCNZ_AHPRA] = useState('');
  const [registerAddress, setRegisterAddress] = useState('');
  const [registerCountry, setRegisterCountry] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const postLoginAction = localStorage.getItem('postLoginAction');
      if (postLoginAction === 'initiateStripeCheckout') {
        const courseId = localStorage.getItem('courseId'); // <-- Retrieve courseId
        initiateStripeCheckout(courseId); // <-- Pass courseId
        localStorage.removeItem('postLoginAction');
        localStorage.removeItem('courseId'); // <-- Clear courseId
      } else {
        navigate('/profile');
      }
    } catch (error) {
      console.error("Error logging in:", error);
      if (error.code === 'auth/wrong-password') {
        alert("The password is incorrect. Please try again.");
      } else {
        alert("Failed to log in. Please check your credentials and try again.");
      }
    }
  };

  const handleForgotPassword = async (email) => {
    if (!email) {
      alert("Please enter your email address.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset email sent!");
    } catch (error) {
      console.error("Error sending password reset email:", error);
      alert("Failed to send password reset email. Please check the email provided and try again.");
    }
  };
  
  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, registerEmail, registerPassword);
      const user = userCredential.user;
  
      // Determine the Firestore collection based on the selected country
      const collection = registerCountry === 'New Zealand' ? 'users_nz' : 'users_au';
  
      // Save user details to Firestore
      const userDocRef = doc(firestore, collection, user.uid);
      await setDoc(userDocRef, {
        uid: user.uid,
        displayName: registerName,
        email: user.email,
        phoneNumber: registerPhoneNumber,
        specialty: registerSpecialty,
        MCNZ_AHPRA: registerMCNZ_AHPRA,
        address: registerAddress,
        country: registerCountry  // Save the country
      });
  
      // Sign in the user after registration
      await signInWithEmailAndPassword(auth, registerEmail, registerPassword);

    const postLoginAction = localStorage.getItem('postLoginAction');
    if (postLoginAction === 'initiateStripeCheckout') {
      const courseId = localStorage.getItem('courseId'); // <-- Retrieve courseId
      initiateStripeCheckout(courseId, registerCountry); // <-- Pass courseId and country
      localStorage.removeItem('postLoginAction');
      localStorage.removeItem('courseId'); // <-- Clear courseId
    } else {
      navigate('/profile');
    }
    setLoading(false); // Stop loading if registration is successful
  } catch (error) {
    console.error("Error registering:", error);
    setLoading(false); // Stop loading in case of an error
  }
};
  
  return (
    <div><Header />
    <div className="login-container"> 
    <div className="login-box">
     <h2>Login</h2>
     <form onSubmit={handleLogin}>
       <input
         type="email"
         placeholder="Email"
         value={email}
         onChange={(e) => setEmail(e.target.value)}
       />
       <input
         type="password"
         placeholder="Password"
         value={password}
         onChange={(e) => setPassword(e.target.value)}
       />
       <button type="submit">Login</button>
       <button type="button" onClick={() => handleForgotPassword(email)} className="forgot-password">
         Forgot Password?
       </button>
     </form>
   </div>

      <div className="register-box">
        <h2>Register</h2>
        <form onSubmit={handleRegister}>
  <input
    type="text"
    placeholder="Name"
    value={registerName}
    onChange={(e) => setRegisterName(e.target.value)}
    required
  />
  <input
    type="tel"
    placeholder="Phone Number"
    value={registerPhoneNumber}
    onChange={(e) => setRegisterPhoneNumber(e.target.value)}
    required
  />
  <select
    value={registerCountry}
    onChange={(e) => setRegisterCountry(e.target.value)}
    className="form-input"
    required
  >
    <option value="">Select Country</option>
    <option value="New Zealand">New Zealand</option>
    <option value="Australia">Australia</option>
  </select>
  <select
    value={registerSpecialty}
    onChange={(e) => setRegisterSpecialty(e.target.value)}
    className="form-input"
    required
  >
    <option value="">Select Specialty</option>
    <option value="Orthopaedics">Orthopaedics</option>
    <option value="ENT">ENT</option>
    <option value="Urology">Urology</option>
    <option value="GeneralSurgery">General Surgery</option>
    <option value="Ophthalmology">Ophthalmology</option>
    <option value="Vascular">Vascular</option>
    <option value="Neurosurgery">Neurosurgery</option>
    <option value="Plastics">Plastics</option>
    <option value="Other">Other</option>
  </select>
  <input
    type="text"
    placeholder="MCNZ / AHPRA no."
    value={registerMCNZ_AHPRA}
    onChange={(e) => setRegisterMCNZ_AHPRA(e.target.value)}
    required
  />
  <input
    type="text"
    placeholder="Address"
    value={registerAddress}
    onChange={(e) => setRegisterAddress(e.target.value)}
    required
  />
  <input
    type="email"
    placeholder="Email"
    value={registerEmail}
    onChange={(e) => setRegisterEmail(e.target.value)}
    required
  />
  <input
    type="password"
    placeholder="Password"
    value={registerPassword}
    onChange={(e) => setRegisterPassword(e.target.value)}
    required
  />
  <button type="submit" disabled={loading}>
  {loading ? <CircularProgress size={24} color="inherit" /> : 'Register'}
</button>
</form>
      </div>
     
    </div>
    <Footer />
    </div>
  );
}

export default Login;
