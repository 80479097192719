import React, { useState } from 'react';
import './Header.css';
import logo from './images/headerlogo.png';

function Footer() {
  const [showNav, setShowNav] = useState(false);
  const [showRefundPolicy, setShowRefundPolicy] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  const refundPolicyContent = `
    <h3>Courses & Packages</h3>
    <ol>
      <li>For cancellations more than 4 weeks prior to course commencement, 100% of the course price will be refunded. For cancellations 2-4 weeks prior to course commencement, 50% of the course will be refunded. There are no refunds for cancellations less than 2 weeks prior to course commencement. No refunds will be made for failure to attend courses.</li>
      <li>No refunds will be made for Question Bank subscriptions.</li>
      <li>Where a question bank access has been granted following the purchase of a course, no refund will be made for the Question bank. For refund of the course please refer to policy 1.</li>
    </ol>
    <h3>Course Cancellation</h3>
    <p>In the unlikely event that insufficient registrations are received to make a course or program viable, or the date or location of a course is changed, iPrep takes no responsibility for additional individual costs incurred as a result.</p>
    <p>Please note that registrations are not confirmed until payment has been processed, which is considered as acceptance of the above terms and conditions.</p>
  `;

  const privacyPolicyContent = `
    <p>As a valued client of iPrep Medical, we are committed to maintaining your privacy.</p>
    <h3>Collection of Information</h3>
    <p>When you visit the iPrep Medical website or use our other online resources we may use cookies and similar tracking technologies including a range of tools provided by third parties such as Facebook and Google to collect or receive information and then use this information to enhance the clients' online experience, deliver more relevant and targeted advertising. This data may also allow for reporting on trends. Information collected may include Internet Protocol (IP), domain name, browser type, date and time of your request, online behaviour such as information on the pages you visit, links and emails you click, and advertisements you select.</p>
    <p>iPrep Medical collects personal details during the subscription and enrolment process. This information is required to provide further education and information about products and services. All reasonable steps are taken to ensure that the subscription and membership database and personal information used to process payments are securely maintained electronically to prevent unauthorised access.</p>
    <p>iPrep Medical does not sell any information to any third party.</p>
    <p>Surveys may be used to help us improve our level of service to you. We do not intend to collect sensitive information and in the event that you believe that information requested might be sensitive, you are under no obligation to provide it.</p>
    <p>Visitors have the 'right to be forgotten'. Please email admin@iprepmedical.com.au with the subject line 'right to be forgotten' should you wish for any personal information to be deleted.</p>
    <h3>Complaints</h3>
    <p>Should you wish to lodge a privacy complaint, you may do so in writing or by email to admin@iprepmedical.com.au. Any privacy complaint will be assessed, reviewed, and responded to within 30 days of submission.</p>
    <h3>Links to other websites</h3>
    <p>This website may contain links to third party websites. iPrep Medical is not responsible for the condition or content on these sites. The links are provided for convenience and do not indicate endorsement of any products. This privacy policy only covers this site. Please access the privacy policy of other sites to determine how your information will be used and stored.</p>
  `;

  return (
    <div className="footer">
      <div className="footer-content">
        <img src={logo} alt="YourLogo" className="logo" />
        <div className="footer-buttons">
          <button onClick={() => setShowRefundPolicy(true)}>Refund Policy</button>
          <button onClick={() => setShowPrivacyPolicy(true)}>Privacy Policy</button>
        </div>
      </div>
      {showRefundPolicy && (
        <div className="popup">
          <div className="popup-content">
            <h2>Refund Policy</h2>
            <div dangerouslySetInnerHTML={{ __html: refundPolicyContent }}></div>
            <button onClick={() => setShowRefundPolicy(false)}>Close</button>
          </div>
        </div>
      )}
      {showPrivacyPolicy && (
        <div className="popup">
          <div className="popup-content">
            <h2>Privacy Policy</h2>
            <div dangerouslySetInnerHTML={{ __html: privacyPolicyContent }}></div>
            <button onClick={() => setShowPrivacyPolicy(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Footer;