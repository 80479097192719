import React from 'react';
import './Courses.css';
import Header from './Header';
import course1 from './images/course1.png';
import course2 from './images/course2.png';
import course3 from './images/course3.png';
import course4 from './images/course4.png';
import { Link } from 'react-router-dom';
import Footer from './Footer'; 
// import other images... // Import Header component to include it in the Courses page

function Courses() {
  const courseDetails = [
    {
      name: "Surgical Education & Training (SET) Interview Courses",
      description: "The Registrar Interview Course is an intensive workshop which prepares junior doctors for the increasingly competitive Non-Training/Unaccredited Registrar interviews. The course covers the key components of the art of interviewing essential for presenting answers polished and confidently to demonstrate suitability for Non-Training/Unaccredited Registrar roles and maximise your chances for selection.",
      image: course1
    },
    {
      name: "Unaccredited Registrar Interview Course",
      description: "The Registrar Interview Course is an intensive workshop which prepares junior doctors for the increasingly competitive Non-Training/Unaccredited Registrar interviews. The course covers the key components of the art of interviewing essential for presenting answers polished and confidently to demonstrate suitability for Non-Training/Unaccredited Registrar roles and maximise your chances for selection. ",
      image: course2
    },
    {
      name: "Anaesthesia Basic Training Interview Course",
      description: "The Anaesthesia Basic Training Interview Course is a workshop which prepares junior doctors for Anaesthesia Basic Training interviews. The course covers key components of the art of delivery essential for presenting polished answers with confidence to demonstrate suitability for Anaesthesia Basic Training and maximise your chances for selection.",
      image: course3
    },
    {
      name: "Online 1:1 Interview Coaching",
      description: "The final obstacle in the way to becoming a specialist in your chosen field is the interview. Because so much weighting is placed on this interview, it is crucial you give yourself the best possible chance of being successful leaving no stone unturned when it comes to your preparation so leave nothing to chance - get one-on-one interview coaching with one of our team experts.",
      image: course4
    }
  ];

  return (
    <div>
      <Header />
      <div className="courses-container">
        <h1 className="courses-title">Medical & Surgical Interview Courses</h1>
        <div className="course-cards">
        {courseDetails.map((course, index) => (
  <div key={index} className="course-card">
    <div className="course-content">
      <h2 className="course-name">{course.name}</h2>
      <p className="course-description">{course.description}</p>
      <div className="course-actions">
        <Link to={`/course_${index+1}`} className="learn-more-button">Learn More</Link>
      </div>
    </div>
    <img className="course-image" src={course.image} alt={course.name} />
  </div>
))}
          
        </div>
      </div>
      <div>
        <Footer/>
        </div>
    </div>
  );
}

export default Courses;
