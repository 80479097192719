import React from 'react';
import Header from './Header';
import './About.css';
import aboutImage from './images/about.png';
import Footer from './Footer'; 

function About() {
    return (
        <div>
            <Header />
            <div className="about-image-container">
                <img src={aboutImage} alt="About Us" className="about-image" />
            </div>
            <div className="about-card">
            <h2 className="about-us-title">About Us</h2>
                <p>iPrep Medical is an Australasian based organisation that provides specialist medical and surgical interview preparation courses to propel health professionals through competitive career interviews.</p>
                <p>Our products have been designed specifically by practicing consultants to prepare junior doctors to be successful in medical and surgical interviews to enable them to reach their career aspirations.</p>
                <p>The organisation was born out of the recognition of a rapidly increasing bottleneck at all stages of medical career progression.  This, combined with a lack of preparatory training available to young health professionals, iPrep Medical was evolved to ensure young health professionals have the best chance possible of being successful in pivotal interviews.</p>
            </div>
            <div className="about-card">
            <h2 className="about-us-title">Our Mission</h2>
                <p>Having walked the same path experiencing many of the same struggles, our mission is to equip you with the ability to confidently articulate and respond effectively in high stakes progression interviews.</p>
                <p>We recognise the importance of showcasing the best version of yourself in your interviews, to achieve your highest vocational aspirations.  We will help you get there. </p>
            </div>
            <div>
            <Footer />
        </div>
        </div>
    );
}

export default About;
