import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions'; // Import this

const firebaseConfig = {
  apiKey: "AIzaSyDNnaEzpBZkjtUpvTjUdX0bzxhvmvoCS4M",
  authDomain: "iprep-15b9d.firebaseapp.com",
  projectId: "iprep-15b9d",
  storageBucket: "iprep-15b9d.appspot.com",
  messagingSenderId: "791497264374",
  appId: "1:791497264374:web:06bf597cd2d79158e870d7",
  measurementId: "G-ZJQBSJ4W4D"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const functions = getFunctions(app); // Initialize this

export { app, auth, firestore, functions }; // Export this