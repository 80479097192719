import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Ensure this CSS file contains styles for the container, blurb, and button
import Header from './Header';
import Footer from './Footer';
import './QuestionsRegister.css';

function QuestionsRegister() {
  const navigate = useNavigate();

  return (
    <div>
      <Header />
      <div className="container10">
        <p className="blurb">
          We understand the significance of thorough preparation in navigating the competitive landscape of medical and surgical interviews. That's why we have meticulously curated an extensive repository of over 500 practice questions tailored specifically for health professionals like you.
          <br /><br />
          Our interview Questions Bank is designed to equip you with the essential tools needed to excel on interview day. Each question is accompanied by a suggested answer or key points to cover, meticulously crafted by seasoned experts in the medical field. Whether you're preparing for SET interviews, medical interviews, fellowship interviews, or any other medical role, our comprehensive Questions Bank covers a diverse range of topics and scenarios to ensure you're thoroughly prepared for the challenge.
          <br /><br />
          Access the Questions Bank anytime, anywhere, and at your own pace, allowing for flexible and convenient preparation that fits into your busy schedule.
          <br /><br />
          We regularly update our Questions Bank to reflect the latest trends and developments in the medical field, ensuring you're always equipped with questions relating to the most relevant and up-to-date topics.
          <br /><br />
          Whether you're a registrar preparing for SET interviews, a registrar gearing up for fellowship interviews, or a seasoned professional exploring new career opportunities, iPrep Medical's Questions Bank is your go-to resource. Don’t leave your interview preparation to chance.
        </p>
        <button onClick={() => navigate('/login')}>Register Now</button>
      </div>
      <Footer />
    </div>
  );
}

export default QuestionsRegister;