import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Switch } from 'react-router-dom';
import Login from './components/Login';
import Profile from './components/Profile';
import Questions from './components/Questions';
import Home from './components/Home';
import Courses from './components/Courses';
import SETcourse from './components/SETcourse';
import About from './components/About';
import Contact  from './components/Contact';
import SuccessPage from './components/SuccessPage';
import RegIntCourse from './components/RegIntCourse';
import ABTcourse from './components/ABTcourse';
import OnlineCourse from './components/OnlineCourse';
import ScrollToTop from './components/ScrollToTop'; // Import the ScrollToTop component
import QuestionsRegister from './components/QuestionsRegister';
import Tutorials from './components/Tutorials';


function App() {
  return (
    <Router>
            <ScrollToTop /> 

     <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/questions" element={<Questions />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/course_1" element={<SETcourse />} />
        <Route path="/course_2" element={<RegIntCourse />} />
        <Route path="/course_3" element={<ABTcourse />} />
        <Route path="/course_4" element={<OnlineCourse />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/payment-success" element={<SuccessPage />} />
        <Route path="/questions_register" element={<QuestionsRegister />} />
        <Route path="/tutorials" element={<Tutorials />} />
     </Routes>
    </Router>
  );
}

export default App;