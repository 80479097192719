import React, { useEffect, useState } from 'react';
import Header from './Header';
import './OnlineCourse.css';
import courseImage from './images/intCoaching.png';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer'; 



function OnlineCourse() {
  const courseTitle = "1:1 Interview Coaching";
  const courseDescription = "The final obstacle in the way to becoming a specialist in your chosen field, is the interview. <br /><br />The significance of this interview on your career progression is greater than any other element in your training or experience to date. Therefore, focused preparation is crucial to give yourself the best possible chance of being successful and securing your future. <br /><br />Take control and get expert individualised coaching from one of our Interview Coaches. Our team is comprised of medical and surgical consultants, fellows, trainees, and other corporate professionals.";
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [specialty, setSpecialty] = useState('');
    const [message, setMessage] = useState('');
    const [sessions, setSessions] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false); // New state variable


    const handleSubmit = (event) => {
      event.preventDefault();
      console.log('Form submission started'); // Debugging line
      fetch('/api/sendEmail2', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              firstName,
              lastName,
              email,
              phoneNumber,
              specialty,
              message,
              sessions,
          }),
      })
      .then(response => {
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          const contentType = response.headers.get('Content-Type');
          if (contentType && contentType.includes('application/json')) {
              return response.json();
          } else {
              return response.text(); // Handle non-JSON responses
          }
      })
      .then(data => {
          console.log('Success:', data);
          setIsSubmitted(true);
          // Clear the form fields
          setFirstName('');
          setLastName('');
          setEmail('');
          setPhoneNumber('');
          setSpecialty('');
          setMessage('');
          setSessions('');
      })
      .catch((error) => {
          console.error('Error:', error);
      });
  };

 
  return (
    <div>
      <Header />
      <div className="set-course-container">
        <div className="oc-course-image-container">
          <img src={courseImage} alt="Course" className="oc-course-image" />
          <h1 className="oc-course-title">{courseTitle}</h1>
        </div>
        <div className="set-course-description-container">
          <p className="set-course-description" dangerouslySetInnerHTML={{ __html: courseDescription }}></p>
        </div>
        <div className="contact1-container">
               
        <form onSubmit={handleSubmit}>
    <h1 class="contact1-us-heading">1:1 Consultation Booking Form</h1>
    <input type="text" placeholder="First Name" required value={firstName} onChange={(e) => setFirstName(e.target.value)} />
    <input type="text" placeholder="Last Name" required value={lastName} onChange={(e) => setLastName(e.target.value)} />
    <input type="email" placeholder="Email Address" required value={email} onChange={(e) => setEmail(e.target.value)} />
    <input type="tel" placeholder="Phone Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
    <input type="text" placeholder="Specialty of Interest" value={specialty} onChange={(e) => setSpecialty(e.target.value)} />
    
    {/* Dropdown for selecting the number of sessions */}
    <select required value={sessions} onChange={(e) => setSessions(e.target.value)} style={{width: '93%', margin: '0 auto', display: 'block', height: '40px', borderColor: 'rgba(10, 179, 120, 1)', borderWidth: '2px', marginTop: '10px', borderRadius: '6px'}}>
        <option value="">Select Number of Sessions</option>
        <option value="2 sessions">2 sessions</option>
        <option value="3 sessions">3 sessions</option>
        <option value="4 sessions">4 sessions</option>
        <option value="5 sessions">5 sessions</option>
        <option value="6 sessions">6 sessions</option>
        <option value="7 sessions">7 sessions</option>
        <option value="8 sessions">8 sessions</option>
    </select>
    <p style={{color: 'rgba(18, 85, 126, 1)', width: '92%', margin: '10px auto', display: 'block'}}>Please note each session is 1 hour long and costs 350AUD / hr + GST</p>
    <textarea placeholder="Please indicate preferred dates for coaching sessions" value={message} onChange={(e) => setMessage(e.target.value)} style={{width: '90%', margin: '0 auto', display: 'block', borderColor: 'rgba(10, 179, 120, 1)', borderWidth: '2px', marginTop: '10px', borderRadius: '6px'}} />
    <button type="submit" className={isSubmitted ? "submitted" : ""}>
                        {isSubmitted ? "Thank You!" : "Submit"}
                    </button>        
</form>
            </div>
<div className="new-course-card-container">


</div>
          </div>
          <div>
            <Footer/>
          </div>
        </div>
      
  );
}

export default OnlineCourse;