import React, { useState, useEffect } from 'react';
import { firestore, auth } from './firebase';
import { collection, onSnapshot, doc, setDoc, getDoc, getDocs } from 'firebase/firestore';
import './Questions.css';
import BookmarkIcon from './BookmarkIcon';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth'; 

function Questions() {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedQuestionId, setExpandedQuestionId] = useState(null);
  const [flags, setFlags] = useState({});
  const [subscriptions, setSubscriptions] = useState([]);
  const [flagCounts, setFlagCounts] = useState({ green: 0, orange: 0, red: 0 });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      if (user) {
        const userId = user.uid;
        const subscriptionSnapshot = await getDocs(collection(firestore, 'users', userId, 'subscriptions'));
        const userSubscriptions = subscriptionSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setSubscriptions(userSubscriptions);
        console.log("Subscriptions:", userSubscriptions);
      } else {
        navigate('/questions_register');
      }
    });
  
    return () => unsubscribe();
  }, [navigate]);

  const fetchFlags = (questionsList) => {
    const userId = auth.currentUser ? auth.currentUser.uid : null;
    if (!userId) return;
  
    const newFlags = {};
    let newFlagCounts = { green: 0, orange: 0, red: 0 };
  
    questionsList.forEach(question => {
      if (!question.id) {
        console.error("Invalid question ID:", question);
        return;
      }
  
      const flagRef = doc(collection(firestore, 'questions', question.id, 'flags'), userId);
      onSnapshot(flagRef, (flagDoc) => {
        if (flagDoc.exists && flagDoc.data()) {
          newFlags[question.id] = flagDoc.data().colour;
          newFlagCounts[flagDoc.data().colour] += 1;
        }
        setFlags({ ...newFlags });
        setFlagCounts({ ...newFlagCounts });
      });
    });
  };

  useEffect(() => {
    if (subscriptions.length === 0) return;

    const unsubscribe = onSnapshot(collection(firestore, 'questions'), snapshot => {
      const questionsList = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      console.log("Fetched Questions:", questionsList);
  
      const activeSubscriptions = subscriptions.filter(sub => sub.status === 'active');
      const activeCategories = activeSubscriptions.map(sub => {
        if (sub.status === 'active') return 'OrthoProfessionalism';
        return null;
      }).filter(Boolean);
      const filteredQuestions = questionsList.filter(question => activeCategories.includes(question.category));
      console.log("Filtered Questions:", filteredQuestions);
      console.log("Active Subscriptions:", activeSubscriptions);
      console.log("Active Categories:", activeCategories);

      setQuestions(filteredQuestions);
      fetchFlags(filteredQuestions);
      setLoading(false);
    });
  
    return () => unsubscribe();
  }, [subscriptions]);

  const toggleAnswer = (questionId) => {
    if (expandedQuestionId === questionId) {
      setExpandedQuestionId(null);
    } else {
      setExpandedQuestionId(questionId);
    }
  };

  const setFlagColour = async (questionId, colour) => {
    const userId = auth.currentUser ? auth.currentUser.uid : null;
    console.log(userId);
  
    if (!userId) {
      console.error("No user authenticated");
      return;
    }
  
    setFlags(prevFlags => ({
      ...prevFlags,
      [questionId]: colour
    }));
  
    try {
      await setDoc(doc(collection(firestore, 'questions', questionId, 'flags'), userId), {
        colour: colour
      });
    } catch (error) {
      console.error("Error setting flag color:", error);
      setFlags(prevFlags => ({
        ...prevFlags,
        [questionId]: flags[questionId]
      }));
    }
  };

  const getColor = (flagColour) => {
    switch (flagColour) {
      case 'green':
        return 'green';
      case 'orange':
        return 'orange';
      case 'red':
        return 'red';
      default:
        return 'grey';
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const hasActiveSubscription = subscriptions.some(sub => sub.status === 'active');
  if (!hasActiveSubscription) {
    navigate('/profile');
    return null;
  }

  return (
    <div className="questions-container">
      <Header />
      <div className="flag-counts">
  <div>
    Easy: {flagCounts.green}
    <div className="slider-container">
      <div className="slider green" style={{ width: `${(flagCounts.green / 20) * 100}%` }}></div>
    </div>
  </div>
  <div>
    Medium: {flagCounts.orange}
    <div className="slider-container">
      <div className="slider orange" style={{ width: `${(flagCounts.orange / 20) * 100}%` }}></div>
    </div>
  </div>
  <div>
    Hard: {flagCounts.red}
    <div className="slider-container">
      <div className="slider red" style={{ width: `${(flagCounts.red / 20) * 100}%` }}></div>
    </div>
  </div>
</div>
      {questions.map(question => (
        <div key={question.id} className="question-card">
          <div className="question-header">
            <h3 onClick={() => toggleAnswer(question.id)}>{question.question}</h3>
            <div className="bookmark-container">
              <BookmarkIcon color={getColor(flags[question.id] || 'grey')} />
            </div>
          </div>

          {expandedQuestionId === question.id && <p>{question.answer}</p>}
          <div className="difficulty-buttons">
            <button onClick={() => setFlagColour(question.id, 'green')} style={{ backgroundColor: getColor('green') }}>Easy</button>
            <button onClick={() => setFlagColour(question.id, 'orange')} style={{ backgroundColor: getColor('orange') }}>Medium</button>
            <button onClick={() => setFlagColour(question.id, 'red')} style={{ backgroundColor: getColor('red') }}>Hard</button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Questions;
