import React, { useEffect, useState } from 'react';
import { auth, firestore } from './firebase';
import { setDoc, doc, getDoc } from 'firebase/firestore';
import './SuccessPage.css'; // Import the CSS file
import { Link } from 'react-router-dom';
import { functions } from './firebase'; // Import functions from firebase
import { httpsCallable } from 'firebase/functions';

const courseCollections = ['SET_course', 'ABTcourse', 'OnlineCourse', 'RegIntCourse'];

const addAttendee = async (userId, courseId) => {
  console.log(`Adding attendee with userId: ${userId} to courseId: ${courseId}`);

  // Assuming 'auth' is your Firebase Auth instance and the user is currently signed in
  const user = auth.currentUser;
  let userEmail = '';

  if (user && user.email) {
    userEmail = user.email;
  } else {
    console.log(`No authenticated user found or user does not have an email.`);
    return; // Exit if there's no authenticated user or the user has no email
  }

  for (let collection of courseCollections) {
    const courseDocRef = doc(firestore, `${collection}/${courseId}`);
    const courseDocSnap = await getDoc(courseDocRef);

    if (courseDocSnap.exists()) {
      const attendeeDocRef = doc(firestore, `${collection}/${courseId}/attendees/${userId}`);
      try {
        await setDoc(attendeeDocRef, {
          userId: userId,
          email: userEmail // Include the user's email here
        });
        console.log(`Successfully added attendee with userId: ${userId} and email: ${userEmail} to courseId: ${courseId}`);
        return;
      } catch (error) {
        console.error(`Failed to add attendee with userId: ${userId} and email: ${userEmail} to courseId: ${courseId}`, error);
      }
    }
  }

  console.log(`No such course with courseId: ${courseId}`);
};

const fetchCourseDetails = async (courseId) => {
  for (let collection of courseCollections) {
    const courseDocRef = doc(firestore, `${collection}/${courseId}`);
    const courseDocSnap = await getDoc(courseDocRef);

    if (courseDocSnap.exists()) {
      return courseDocSnap.data();
    }
  }

  console.log(`No such course with courseId: ${courseId}`);
  return null;
};
  
  function SuccessPage() {
    const [user, setUser] = useState(null);
    const [courseDetails, setCourseDetails] = useState(null); // Add this line
  
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        setUser(user);
      });
  
      // Clean up the listener when the component unmounts
      return () => unsubscribe();
    }, []);
  
    useEffect(() => {
      if (user) {
        (async () => {
          const urlParams = new URLSearchParams(window.location.search);
          const courseId = urlParams.get('courseId');
  
          console.log("Current user: ", user); // Print current user
          console.log("Course ID: ", courseId); // Print course ID
  
          if (courseId) {
            await addAttendee(user.uid, courseId);
            const details = await fetchCourseDetails(courseId);
            if (details) {
              setCourseDetails(details);
  
              // Send email
              const sendEmail = httpsCallable(functions, 'sendEmail');
sendEmail({ email: user.email, courseDetails: details })
  .then(() => console.log('Email sent!'))
  .catch((error) => console.error('Error sending email:', error));
            }
          }
        })();
      }
    }, [user]);

    return (
        <div className="success-container">
          <div className="success-card">
            <h1 className="success-title">Payment Successful</h1>
            {/* ... your other UI elements ... */}
          {courseDetails && (
            <div className="course-details">
              <h2>Course Details:</h2>
              <p>Address: {courseDetails.Address}</p>
              <p>Location: {courseDetails.Location}</p>
              <p>Date Start: {courseDetails.DateStart}</p>
              <p>Date Finish: {courseDetails.DateFinish}</p>
              <p>Time: {courseDetails.Time}</p>
            </div>
          )}
          <Link to="/" className="home-button">Home</Link>
          </div>
        </div>
      );
    }

export default SuccessPage;
